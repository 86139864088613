import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "@emotion/styled"

export const StickyImage = () => {
    const data = useStaticQuery(graphql`
        query {
            contentfulHeaderImg {
                img {
                    file {
                        url
                    }
                }
            }
        }
    `)
    return <StickyImageOnTop background={data.contentfulHeaderImg.img.file.url} />
}

const StickyImageOnTop = styled.div`
    background-image: linear-gradient(0deg, rgba(74, 120, 181, 0.6) 0%, rgba(74, 120, 181, 0.6) 100%), url(${(props) => props.background});
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    width: 100%;
    min-height: 10rem;
`
