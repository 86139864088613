import styled from "@emotion/styled"
import { medias } from "../css/media"

export const MainComponent = styled.div`
    margin: 2rem 1.5rem;
    min-height: 30vh;
`
export const Body = styled.div`
    width: 96%;
    font-size: 0.7rem;

    & > p {
        padding-right: 3rem;
    }
    @media (${medias.tablet}) {
        width: 92%;
    }
`
export const SideImg = styled.img`
    width: 6rem;
    height: auto;
    align-self: flex-start;
    float: right;

    @media (${medias.tablet}) {
        width: 10rem;
    }
    @media (${medias.desktop}) {
        width: 14rem;
    }
`
export const TBListComponent = styled.div`
    text-align: center;
    list-style-position: inside;
    background-color: ${(props) => props.color || "#f7c335"};
    padding: 0.5rem;
    margin: 0 auto;
    min-height: 31vh;
`
export const TBWBody = styled.div`
    font-size: 0.7rem;
    & li {
        width: auto;
        margin: 0.5rem auto;
    }
    & li p {
        display: inline;
    }
    & ul,
    & ol {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-left: 0;
        list-style: decimal;
        list-style-position: inside;
    }

    & > p {
        font-weight: bold;
    }
`
