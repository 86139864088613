import React from "react"
import Layout from "../components/layout"
import styled from "@emotion/styled"
import { SectionTitle } from "../components/SectionTitle"
import { graphql, useStaticQuery } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { StickyImage } from "../components/stickyImage"
import { medias } from "../css/media"
import Link from "gatsby-link"
import { Body, MainComponent, SideImg, TBListComponent, TBWBody } from "../components/about"

const Associations = () => {
    const pageData = useStaticQuery(graphql`
        query {
            contentfulSectionAssociations {
                title
            }
            contentfulSectionAssociationsBodyRichTextNode {
                json
            }
            contentfulFooterLogo {
                logo {
                    file {
                        url
                    }
                }
            }
            contentfulTbwAp {
                title
            }
            contentfulTbwApBodyRichTextNode {
                json
            }
        }
    `)

    return (
        <Layout>
            <StickyImage />
            <MainComponent>
                <SectionTitle title={pageData.contentfulSectionAssociations.title} />
                <Body>
                    <SideImg src={pageData.contentfulFooterLogo.logo.file.url} alt="side image" />
                    <div>{documentToReactComponents(pageData.contentfulSectionAssociationsBodyRichTextNode.json)}</div>
                </Body>
                <SignUpButton to="/app/signup">S'INSCRIRE</SignUpButton>
            </MainComponent>
            <TBListComponent color="#3193d1">
                <h5>{pageData.contentfulTbwAp.title}</h5>
                <TBWBody>{documentToReactComponents(pageData.contentfulTbwApBodyRichTextNode.json)}</TBWBody>
            </TBListComponent>
            {/* TODO : association shortlist*/}
        </Layout>
    )
}

export default Associations

const SignUpButton = styled(Link)`
  font-size: .7rem;
  text-decoration: none;
  padding: .5rem 1.3rem;
  color: white;
  background-color: #E66F47;
  border-radius: 10px;
  font-weight: bold;
  
  /*media(${medias.tablet}) {
    float: left;
  }*/
`
